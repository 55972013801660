import { Select } from 'antd'
import '../styles/List.scss'

export const ListDevices = ({ devices, groups, setNewGroup }) => {

    const groupSelector = (groups, defaultGroup, serialNumber, setNewGroup) => {
        return <Select
            onSelect={(value) => { setNewGroup(serialNumber, value) }}
            defaultValue={defaultGroup}
            options={groups.map((group) => ({ value: group.name, label: group.name }))}
            style={{ width: '100%' }}
        />
    }

    return (
        <div className='container'>
            <h2>Devices</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Vehicle Name</th>
                        <th>Group</th>
                        <th>Agent Version</th>
                        <th>App Version</th>
                        <th>Before Alert Duration</th>
                        <th>After Alert Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((device, i) => (
                        <tr key={i} style={{ backgroundColor: `${groups.find((g) => g.name === device.group)?.color}a5` || '#ffffffa5' }}>
                            <td>{device.serial_number}</td>
                            <td>{device.vehicle_name}</td>
                            <td style={{ padding: '0px' }}>{groupSelector(groups, device.group, device.serial_number, setNewGroup)}</td>
                            <td>{device.versions.agent_version}</td>
                            <td>{device.versions.app_version}</td>
                            <td>{device.video_durations.before_alert_duration}</td>
                            <td>{device.video_durations.after_alert_duration}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}