import { useEffect, useState } from "react"
import { Button, Divider, Input, Modal, Select } from "antd"

export const ConfigureDevice = ({ open, setOpen, createNewRad, groups, error }) => {
    const [serialNumber, setSerialNumber] = useState("")
    const [group, setGroup] = useState(undefined)

    useEffect(() => {
        console.log(open)
        setSerialNumber("")
        setGroup(undefined)
    }, [open])

    return (
        <Modal open={open} width={800} footer={null} onCancel={() => { setOpen(false) }}>
            <div className="configure-device">
                <h2>Configure Device</h2>
                <Divider style={{ marginTop: "10px" }} />
                <Input
                    placeholder="Serial Number"
                    style={{ marginBottom: "10px" }}
                    value={serialNumber}
                    onChange={(e) => { setSerialNumber(e.target.value) }}
                />
                <Select
                    placeholder="Select Group"
                    onSelect={(value) => { setGroup(value) }}
                    value={group}
                    options={groups.map((group) => { return { "label": group.name, "value": group.name } })}
                    style={{ width: '100%' }}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                <Button type="primary" style={{ marginTop: "10px" }} onClick={() => { createNewRad(serialNumber, group) }}>Create New RAD</Button>
            </div>
        </Modal>
    )
}