import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { Button, Divider } from "antd";
import { getDevices, getGroups, setNewRadGroup, setNewGroupColor, newRad } from "../../store";
import { ListDevices } from "./components/ListDevices";
import { ListGroups } from "./components/ListGroups";
import { ConfigureDevice } from "./components/ConfigureDevice";
import { ACCOUNT_PAGE } from "../../configuration/paths";
import './styles/index.scss'

export const DeviceDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector(state => state.app.profile.role);
    const [devices, setDevices] = useState([]);
    const [groups, setGroups] = useState([]);
    const [makeNewRad, setMakeNewRad] = useState(false);
    const [newRadError, setNewRadError] = useState("");

    const createNewRad = (serialNumber, groupName) => {
        dispatch(newRad({ "serial_number": serialNumber, "group": groupName }))
            .then((response) => {
                if (response.payload === 200) {
                    setMakeNewRad(false)
                    dispatch(getDevices())
                        .then((response) => {
                            setDevices(response.payload)
                        })
                } else {
                    setNewRadError("Failed to create new RAD. Please check the serial number to see if it already exists.")
                }
            })
    }

    const setNewColor = (group_name, color) => {
        const group = groups.find((g) => g.name === group_name)
        const oldColor = group.color
        group.color = color
        dispatch(setNewGroupColor({ "group": group_name, "color": color }))
            .then((response) => {
                if (response.payload !== 200) {
                    group.color = oldColor
                }
            })
        setGroups([...groups])
    }

    const setNewGroup = (serialNumber, groupName) => {
        const device = devices.find((d) => { console.log(d.serial_number, serialNumber); return d.serial_number === serialNumber })
        const oldGroup = device.group
        device.group = groupName
        dispatch(setNewRadGroup({ "serial_number": serialNumber, "group": groupName }))
            .then((response) => {
                if (response.payload !== 200) {
                    device.group = oldGroup
                }
            })
        setDevices([...devices])
    }

    useEffect(() => {
        if (role && role !== 'special') {
            navigate(ACCOUNT_PAGE)
        }
    }, [role])

    useEffect(() => {
        dispatch(getDevices())
            .then((response) => {
                setDevices(response.payload)
            })
        dispatch(getGroups())
            .then((response) => {
                setGroups(response.payload)
            }
            )
    }, [dispatch])

    useEffect(() => {
        if (!makeNewRad) {
            setNewRadError("")
        }
    }, [makeNewRad])

    return (
        <div className="main-container">
            <div className="actions-container">
                <h2>Actions</h2>
                <Button onClick={() => setMakeNewRad(true)}>Make New RAD</Button>

            </div>
            <Divider />
            <ListDevices devices={devices} groups={groups} setNewGroup={setNewGroup} />
            <Divider />
            <ListGroups groups={groups} setNewColor={setNewColor} />
            <ConfigureDevice open={makeNewRad} setOpen={setMakeNewRad} createNewRad={createNewRad} groups={groups} error={newRadError} />
        </div>
    )
}