import { configureStore, createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import request, { DELETE, GET, PATCH, POST } from "./request";
import _ from "lodash";
import { get } from './localstorage';

export const getLandingVideos = createAsyncThunk(
  'landing/videos',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/landing/')
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"));
    }
  }
)

export const sendEmail = createAsyncThunk(
  'email',
  async (act, thunk) => {
    try {
      await request(POST, '/api/email/', act)
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const notifyGroup = createAsyncThunk(
  'test',
  async (act, thunk) => {
    try {
      return await request(POST, '/api/test_notify_group/', act, getAuthConfig(thunk));
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"));
    }
  }
)

export const login = createAsyncThunk(
  'auth/login',
  async (act, thunk) => {
    try {
      // const response = await request(POST, `/api/login/`, act);
      request(POST, '/api/login/', act)
        .then((response) => {
          if (response.status === 200) {
            thunk.dispatch(updateAuth({ token: response.data.token }))
            thunk.dispatch(getProfile())
          }
          if (act && act.successCallback) {
            act.successCallback(response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
      // thunk.dispatch(updateAuth({ token: response.data.token }))
      // thunk.dispatch(getProfile())
      // if (act && act.successCallback) {
      //   act.successCallback(response.data)
      // }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const resetPasswordRequest = createAsyncThunk(
  'auth/password_reset_request',
  async (act, thunk) => {
    try {
      return await request(POST, '/api/password_reset/request/', { email: act })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const changePassword = createAsyncThunk(
  'auth/change_password',
  async (act, thunk) => {
    try {
      const response = request(POST, '/api/change_password/', act, getAuthConfig(thunk))
      return response
        .then((response) => {
          return response.data
        })
        .catch((reason) => {
          return reason.response.data
        })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const resetPassword = createAsyncThunk(
  'auth/password_rest',
  async (act, thunk) => {
    try {
      const response = request(POST, `/api/password_reset/verify/`, act)
      return response
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

const getAuthConfig = (thunk, extraHeaders = {}) => {
  const token = _.get(thunk.getState(), "app.auth.token");
  return { headers: { Authorization: `Token ${token}`, ...extraHeaders } }
}

export const getProfile = createAsyncThunk(
  'auth/profile',
  async (act, thunk) => {
    try {
      const response = await request(GET, `/api/users/me/`, {}, getAuthConfig(thunk));
      thunk.dispatch(updateProfile(response.data))
      if (act && act.successCallback) {
        act.successCallback(response.data)
      }
    } catch (e) {
      console.error(e)
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getSummaryDates = createAsyncThunk(
  'auth/profile/alarmhistory/summary_dates',
  async (act, thunk) => {
    try {
      const config = {
        params: act,
        headers: getAuthConfig(thunk).headers
      }
      const response = await request(GET, '/api/summary/get_dates/', {}, config);
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getSummary = createAsyncThunk(
  'auth/profile/alarmhistory/summary',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/summary/get_summary/', act, getAuthConfig(thunk));
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getVehicles = createAsyncThunk(
  'auth/profile/vehicles',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/vehicles/get_dashboard_info/', { serial_number: act }, getAuthConfig(thunk));
      thunk.dispatch(updateVehicles(response.data))
      return response.status
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getUnitNames = createAsyncThunk(
  'auth/profile/vehicles/names',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/detections/get_units/', {}, getAuthConfig(thunk))
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getAppVersions = createAsyncThunk(
  'auth/profile/vehicles/app_versions',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/detections/get_app_versions/', {}, getAuthConfig(thunk));
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getDevices = createAsyncThunk(
  'auth/profile/devices/get_devices',
  async(act, thunk) => {
    try {
      const response = await request(GET, '/apiv2/devices/get_devices/', {}, getAuthConfig(thunk))
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'reponse.data', 'Unknown'))
    }
  }
)

export const newRad = createAsyncThunk(
  'auth/profile/devices/add_rad',
  async(act, thunk) => {
    try {
      const response = await request(POST, '/apiv2/devices/add_rad/', act, getAuthConfig(thunk))
      return response.status
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const setNewRadGroup = createAsyncThunk(
  'auth/profile/devices/set_new_rad_group',
  async(act, thunk) => {
    try {
      const response = await request(POST, '/apiv2/devices/set_group/', act, getAuthConfig(thunk))
      return response.status
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const getGroups = createAsyncThunk(
  'auth/profile/devices/get_groups',
  async(act, thunk) => {
    try {
      const response = await request(GET, '/apiv2/devices/get_groups/', {}, getAuthConfig(thunk))
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const setNewGroupColor = createAsyncThunk(
  'auth/profile/devices/set_group_color',
  async(act, thunk) => {
    try {
      console.log('setNewGroupColor', act)
      const response = await request(POST, '/apiv2/devices/set_group_color/', act, getAuthConfig(thunk))
      return response.status
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const getDeviceConfiguration = createAsyncThunk(
  'auth/profile/devices/get_device_configuration',
  async(act, thunk) => {
    try {
      const response = await request(GET, `/apiv2/devices/get_configuration?device=${act}`, {}, getAuthConfig(thunk))
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const getSingleAlert = createAsyncThunk(
  'auth/profile/alerts/single',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/detections/get_single_detection/', act, getAuthConfig(thunk))
      thunk.dispatch(appendAlert(response.data))
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getVehicleAlerts = createAsyncThunk(
  'auth/profile/alerts',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/detections/get_detections/', act, getAuthConfig(thunk))
      thunk.dispatch(updateAlerts(response.data))
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getCSVData = createAsyncThunk(
  'auth/profile/alerts/csv',
  async (act, thunk) => {
    const response = await request(POST, '/api/detections/get_csv_data/', act, getAuthConfig(thunk))
    return response.data
  }
)

export const updateUserProfile = createAsyncThunk(
  'auth/profile/update_user_profile',
  async (data, thunk) => {
    try {
      const response = await request(PATCH, '/api/users/update_part/', data, getAuthConfig(thunk));
      thunk.dispatch(getProfile())
      return response.status
    } catch (e) {
      console.error(e)
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const createUser = createAsyncThunk(
  'auth/profile/create_user',
  async (user_data, thunk) => {
    try {
      const data = {
        ...user_data,
        group_manager: false,

      }
      return await request(POST, `/api/users/`, data, getAuthConfig(thunk))
        .then((response) => {
          return { status: response.status, text: 'User Created' }
        })
        .catch((response) => {
          const errors = [response.response.data.username?.[0], response.response.data.email?.[0]]
          return { status: response.response.status, errors: errors }
        })

    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const updateVehicleName = createAsyncThunk(
  'auth/vehicle/name/update',
  async (act, thunk) => {
    try {
      const unitId = act.unit_id
      delete act.unit_id
      await request(PATCH, '/api/vehicles/update_name/', act, getAuthConfig(thunk))
        .then((response) => {
          thunk.dispatch(getVehicles(unitId))
        })
    } catch (e) {
      console.log('Error:', e)
      return thunk.rejectWithValue(_.get(e, 'response.data', 'Unknown'))
    }
  }
)

export const verify = createAsyncThunk(
  'auth/profile/verify',
  async (action, thunk) => {
    try {
      return await request('POST', '/api/verify/', action, getAuthConfig(thunk))
        .then((response) => {
          return { status: response.status, data: response.data }
        })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const addVehicle = createAsyncThunk(
  'vehicle/add',
  async (act, thunk) => {
    try {
      const form = new FormData();
      form.append("name", act.name)
      form.append("source", act.source[0].originFileObj)
      const response = await request(POST, `/api/vehicles/`, form, getAuthConfig(thunk));
      thunk.dispatch(getProfile())
      if (act && act.successCallback) {
        act.successCallback(response.data)
      }
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const removeVehicle = createAsyncThunk(
  'auth/remove',
  async (act, thunk) => {
    try {
      const response = await request(DELETE, `/api/vehicles/${act.id}/`, {}, getAuthConfig(thunk));
      thunk.dispatch(getProfile())
      if (act && act.successCallback) {
        act.successCallback(response.data)
      }
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const listVideos = createAsyncThunk(
  'auth/list_videos',
  async (act, thunk) => {
    try {
      const response = await request(GET, `/api/list_videos/${act.serial_number}/${act.title}/L`, {}, getAuthConfig(thunk));
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getDownloadFiles = createAsyncThunk(
  'auth/get_download_files',
  async (act, thunk) => {
    try {
      const response = await request(GET, `/api/list_videos/${act.serial_number}/${act.title}/D`, {}, getAuthConfig(thunk));
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getAlert = createAsyncThunk(
  'auth/get_alert',
  async (act, thunk) => {
    try {
      const response = await request(GET, `/api/get_alert/${act}`, {}, getAuthConfig(thunk));
      return response.data
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getTimeBlocks = createAsyncThunk(
  'auth/profile/get_time_blocks',
  async (act, thunk) => {
    try {
      return await request('GET', '/api/time_blocks/', {}, getAuthConfig(thunk))
        .then((response) => {
          thunk.dispatch(updateTimeBlocks(response.data))
        })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const createTimeBlock = createAsyncThunk(
  'auth/profile/create_time_block',
  async (act, thunk) => {
    try {
      return await request(POST, '/api/time_blocks/', act, getAuthConfig(thunk))
        .then((response) => {
          thunk.dispatch(getTimeBlocks())
          return { status: response.status, text: 'Created Time Block' }
        })

    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"));
    }
  }
)

export const deleteTimeBlock = createAsyncThunk(
  'auth/profile/delete_time_block',
  async (act, thunk) => {
    try {
      const config = {
        data: act,
        headers: getAuthConfig(thunk).headers
      }
      return await request(DELETE, `/api/time_blocks/`, {}, config)
        .then((response) => {
          thunk.dispatch(getTimeBlocks())
          return { status: response.status, text: 'Deleted Time Block' }
        })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"));
    }
  }
)

export const updateTimeBlock = createAsyncThunk(
  'auth/profile/update_time_block',
  async (act, thunk) => {
    try {
      return await request(PATCH, '/api/time_blocks/', act, getAuthConfig(thunk))
        .then((response) => {
          thunk.dispatch(getProfile())
          return { status: response.status, text: 'Updated Time Block' }
        })
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

// For the management page
export const getAllConfigs = createAsyncThunk(
  'auth/profile/management/available',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/manage/get_all_configs/', {}, getAuthConfig(thunk))
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getMainGroup = createAsyncThunk(
  'auth/profile/management/getMainGroup',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/manage/get_main_group/', {}, getAuthConfig(thunk))
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const getUserLicencesLeft = createAsyncThunk(
  'auth/group/management/licences',
  async (act, thunk) => {
    const response = await request(GET, '/api/manage/get_user_licences_left/', {}, getAuthConfig(thunk))
    return { data: response.data, status: response.status }
  }
)

// Group Management functions
const manageGroup = async (act, thunk, url) => {
  try {
    const response = await request(POST, `/api/manage/${url}/`, act, getAuthConfig(thunk))
    return { data: [...response.data], status: response.status }
  } catch (e) {
    console.log(e)
    return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
  }
}

export const addGroup = createAsyncThunk(
  'auth/profile/addGroup',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'add_group')
  }
)

export const renameGroup = createAsyncThunk(
  'auth/profile/renameGroup',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'rename_group')
  }
)

export const removeGroup = createAsyncThunk(
  'auth/manage/removeGroup',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_group')
  }
)

export const addSubgroup = createAsyncThunk(
  'auth/profile/addSubgroup',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'add_subgroup')
  }
)

export const removeSubgroup = createAsyncThunk(
  'auth/profile/removeSubgroup',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_subgroup')
  }
)

export const addSubUser = createAsyncThunk(
  'auth/profile/addSubUser',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'add_sub_user')
  }
)

export const removeSubUser = createAsyncThunk(
  'auth/profile/addSubUser',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_sub_user')
  }
)

export const addSubVehicle = createAsyncThunk(
  'auth/profile/addSubVehicle',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'add_sub_vehicle')
  }
)

export const removeSubVehicle = createAsyncThunk(
  'auth/profile/addSubVehicle',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_sub_vehicle')
  }
)

// User Management Functions
export const validateUsername = createAsyncThunk(
  'auth/profile/checkUsername',
  async (act, thunk) => {
    try {
      const response = await request(POST, `/api/manage/validate_username/`, act, getAuthConfig(thunk))
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Unknown"))
    }
  }
)

export const createNewUser = createAsyncThunk(
  'auth/profile/addNewUser',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'create_user')
  }
)

export const removeUser = createAsyncThunk(
  'auth/profile/removeUser',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_user')
  }
)

export const changeUserRole = createAsyncThunk(
  'auth/profile/changeUserRole',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'change_role')
  }
)

export const addUserVehicle = createAsyncThunk(
  'auth/profile/addUserVehicle',
  async (act, thunk) => {
    console.log('here')
    return await manageGroup(act, thunk, 'add_user_vehicle')
  }
)

export const removeUserVehicle = createAsyncThunk(
  'auth/profile/addUserVehicle',
  async (act, thunk) => {
    return await manageGroup(act, thunk, 'remove_user_vehicle')
  }
)

// For setting up a new account
export const validateToken = createAsyncThunk(
  'auth/createAccount',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/create_account/check_token/', act)
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Failed to validate token."))
    }
  }
)

export const regenToken = createAsyncThunk(
  'auth/regenToken',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/create_account/regen_token/', act)
      return { status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Failed to validate token."))
    }
  }
)

export const verifyUsername = createAsyncThunk(
  'auth/createAccount/verifyUsername',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/create_account/validate_username/', act)
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Failed to validate token."))
    }
  }
)

export const sendAccountInfo = createAsyncThunk(
  'auth/setupAccount',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/create_account/setup_account/', act)
      console.log('store', response)
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Failed to validate token."))
    }
  }
)

// Vehilce Management Functions
export const renameVehicle = createAsyncThunk(
  'auth/profile/renameVehicle',
  async (act, thunk) => {
    try {
      const response = await request(POST, '/api/manage/rename_vehicle/', act, getAuthConfig(thunk))
      console.log('store', response)
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Failed to validate token."))
    }
  }
)

export const getMetricsDashboard = createAsyncThunk(
  'auth/profile/metrics',
  async (act, thunk) => {
    try {
      const response = await request(GET, '/api/uptime/', {}, getAuthConfig(thunk))
      return { data: response.data, status: response.status }
    } catch (e) {
      return thunk.rejectWithValue(_.get(e, "response.data", "Could not get metrics dashbaord."))
    }
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    auth: {
      token: get("auth_token") || null,
      error: null,
    },
    profile: {
      vehicles: [],
      error: null,
    },
    nav: {
      modal: null,
      error: null,
    }
  },
  reducers: {
    updateAuth: (state, action) => {
      state.auth = _.merge(state.auth, action.payload)
    },
    updateNav: (state, action) => {
      state.nav = _.merge(state.nav, action.payload)
    },
    updateProfile: (state, action) => {
      state.profile = _.merge(state.profile, action.payload)
    },
    updateVehicles: (state, action) => {
      if (action.payload) {
        state.vehicles = action.payload
      }
    },
    updateAlerts: (state, action) => {
      if (action.payload) {
        state.alerts = action.payload.map((val, i) => [i, val])
      }
    },
    appendAlert: (state, action) => {
      if (action.payload) {
        state.alerts = [...state.alerts, [state.alerts.length, action.payload]]
      }
    },
    updateTimeBlocks: (state, action) => {
      if (action.payload) {
        state.timeBlocks = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.auth.error = null
    })
    builder.addCase(login.rejected, (state, action) => {
      state.auth.error = action.payload
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.profile.error = action.payload
    })
    builder.addCase(createUser.rejected, (state, action) => {
      state.profile.error = action.payload
    })
    builder.addCase(addVehicle.rejected, (state, action) => {
      state.nav.error = action.payload
    })
  },
})

export const { updateAuth, updateNav, updateProfile, updateVehicles, updateAlerts, appendAlert, updateTimeBlocks } = appSlice.actions;

export default configureStore({
  reducer: {
    app: appSlice.reducer,
  }
})
