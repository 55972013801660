import { useState } from 'react'
import { ColorPicker } from 'antd'
import '../styles/List.scss'

export const ListGroups = ({ groups, setNewColor }) => {
    const [color, setColor] = useState('#ffffff')
    const [selectedGroup, setSelectedGroup] = useState(undefined)

    const rgbToHex = (rgb) => {
        const rgbArray = rgb.match(/\d+/g).map(Number);
        return '#' + rgbArray.map(x => ('0' + x.toString(16)).slice(-2)).join('');
    }

    return (
        <div className="container">
            <h2>Groups</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Group Name</th>
                        <th>Group Color</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        groups.map((group, i) => (
                            <tr key={i}>
                                <td>{group.name}</td>
                                <td>
                                    {
                                        <ColorPicker
                                            value={selectedGroup === group.name ? color : group.color}
                                            disabledAlpha
                                            onOpenChange={(open) => {open ? setColor(group.color) : setNewColor(group.name, color); setSelectedGroup(group.name)}}
                                            onChange={(value, css) => { setColor(rgbToHex(css)) }}
                                        />
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}